<template>
    <div class="flex flex-col h-screen">
        <NavbarComponent />
        <main class="mt-20">
            <section class="grow top-bar">
                <section class="flex-1  h-full">
                    <div class="pl-8 mx-auto z-10">
                        <div class="mt-32 mb-20">
                            <h1 class="text-center text-gray-900 font-poppings font-semibold text-2xl md:text-4xl">
                                <span class="relative z-20">{{ $t('ContactPage.title')}}</span>
                            </h1>
                            <p class="text-center text-xl font-poppings text-gray-900 mt-3 mb-5">
                              {{ $t('ContactPage.subTitle') }}
                            </p>
                        </div>
                    </div>
                </section>
            </section>
            <section class="flex flex-col mt-10 md:mb-10 px-2 md:px-20 mb-20">
                <div class="md:mx-auto md:w-11/12">
                    <div class="flex flex-col md:flex-row w-full shadow-2xl mb-20 bg-white">
                        <div class="flex flex-col w-full md:w-2/5 bg-blue-900 rounded-md">
                            <div class="mt-10 px-8 py-4">
                                <h1 class="text-4xl font-poppings font-semibold text-white"> {{ $t('ContactPage.contactInformation') }}</h1>
                                <h6 class="text-2xl font-poppings font-semibold text-white">
                                     {{ $t('ContactPage.contactDescription') }}
                                </h6>
                            </div>
                            <div class="flex flex-col space-y-8 mt-10 px-8 py-4">
                                <div class="flex space-x-8 items-center">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-yellow-200" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                                        </svg>
                                    </span>
                                    <span class="text-white text-xl font-semibold">
                                        010 641 12 12
                                    </span>
                                </div>
                                <div class="flex space-x-8 items-center">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-yellow-200" viewBox="0 0 20 20" fill="currentColor">
                                            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                        </svg>
                                    </span>
                                    <span class="text-white text-xl font-semibold">
                                        info@golfigo.se
                                    </span>
                                </div>
                                <div class="flex space-x-8 items-center">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-yellow-200" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span class="text-white text-xl font-semibold">
                                        Mässans gata 10, Göteborg
                                    </span>
                                </div>
                            </div>
                            <div class="flex justify-start">
                                <img class="h-20 w-28" src="./../../assets/golfigo/elip-left.png" alt="">
                            </div>
                        </div>
                        <div class="w-full md:w-3/5">
                            <form @submit.prevent="processContactInformation" class="contact-form flex flex-col space-y-7 px-5 md:px-24 py-10">
                                <div class="form-group">
                                    <label for="fullname" class="text-gray-900 text-xl font-medium">{{ $t('ContactPage.fields.full_name') }}</label>
                                    <input v-model="contactForm.full_name" type="text" class="w-full text-lg text-gray-600 border-b-2 border-light-teal focus:outline-none px-1 py-2 placeholder:text-gray-400" :placeholder="$t('ContactPage.fields.full_name')" required>
                                </div>
                                <div class="form-group">
                                    <label for="email" class="text-gray-900 text-xl font-medium">{{ $t('ContactPage.fields.email') }}</label>
                                    <input v-model="contactForm.email" type="email" class="w-full text-lg text-gray-600 border-b-2 border-light-teal focus:outline-none px-1 py-2 placeholder:text-gray-400" :placeholder="$t('ContactPage.fields.email')" required>
                                </div>
                                <div class="form-group">
                                    <label for="mobile" class="text-gray-900 text-xl font-medium">{{ $t('ContactPage.fields.phone_number') }}</label>
                                    <input v-model="contactForm.phone_number" type="text" class="w-full text-lg text-gray-600 border-b-2 border-light-teal focus:outline-none px-1 py-2 placeholder:text-gray-400" :placeholder="$t('ContactPage.fields.phone_number')">
                                </div>
                                <div class="form-group">
                                    <label for="message" class="text-gray-900 text-xl font-medium">{{ $t('ContactPage.fields.message')}}</label>
                                    <input type="text" v-model="contactForm.message" class="w-full text-lg text-gray-600 border-b-2 border-light-teal focus:outline-none px-1 py-2 placeholder:text-gray-400" :placeholder="$t('ContactPage.fields.message')" required>
                                </div>
                                <div class="form-group flex items-center justify-between">
                                    <div>
                                        <span class="response p-2 text-lg border rounded-md hidden"></span>
                                    </div>
                                    <button :disabled="processing" class="flex px-4 py-2 focus:outline-none bg-theme-sidebar text-white rounded-md">
                                        <span v-if="processing" class="flex justify-center items-center space-x-2">
                                            <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            <span class="text-xl md:text-2xl text-white">{{ $t('Message.loading')}}</span>
                                        </span>
                                        <span v-else class="text-xl md:text-2xl text-white">
                                            {{$t('ContactPage.fields.submitContactMessage')}}
                                        </span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        <FooterComponent />
    </div>
</template>

<script>
    import NavbarComponent from './components/Navbar.vue'
    import FooterComponent from './components/Footer.vue'
    import FaqRenderVue from './custom/AccordionFaq.vue'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import useAppConfig from '@core/app-config/useAppConfig';
    import {mapGetters} from "vuex";
    export default {
        name : 'ContactPage',
        components : { NavbarComponent, FooterComponent, FaqRenderVue },
        data () {
            return {                
                config: useAppConfig(),
                contactForm : {
                    email : '',
                    full_name : '',
                    phone_number : '',
                    message : '',
                },
                processing : false,
            }
        },
        computed :{
            getLang() {
                return this.config.lang.value;
            },
            message() {
              return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
            },
          ...mapGetters({
            GET_LOCALE:'appConfig/getLocale'
          })
        },
        mounted () {

        },
        methods : {
            processContactInformation () {
                if (this.contactForm.full_name === '' || this.contactForm.email === '' ||
                this.contactForm.message === '') {
                    return
                } else {
                    this.processing = true
                    this.$useJwt.createContactUsInfo(this.contactForm)
                    .then(res => {
                        this.processing = false
                        this.clearFormField()
                        this.popup(
                            res.data[this.message],
                            'success',
                            this.$t('Message.Success'),
                            'CoffeeIcon'
                        );
                    })
                    .catch(err => {
                        this.popupMsg(
                            this.$t('Message.Failed'),
                            err.response.data[this.message],
                            'AlertTriangleIcon',
                            'danger'
                        );
                    })
                }
            },
            clearFormField () {
                Object.keys(this.contactForm).forEach(el => this.contactForm[el] = '');
            },
            popup(message, variant, title, icon) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: title,
                        icon: icon,
                        variant: variant,
                        text: message,
                    },
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
    // .support-gradient {
    //     font-style: normal;
    //     font-weight: 794;
    //     font-size: 40px;
    //     line-height: 48px;
    //     text-align: center;
    //     background: linear-gradient(266.01deg, #0B0875 12.33%, #00D090 113.67%);
    //     -webkit-background-clip: text;
    //     -webkit-text-fill-color: transparent;
    //     background-clip: text;
    //     text-fill-color: transparent;
    // }
</style>